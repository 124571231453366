@use '@button' as *;
@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.with-items {
  display: flex;
  width: 100%;

  gap: 24px;

  @include min-1440-break {
    gap: 32px;
  }
}
