@use '@button' as *;
@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.items {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}

.item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 32px;
  height: 64px;

  @include min-428-break {
    height: 80px;
  }

  @include min-1024-break {
    height: 118px;
  }

  @include min-1440-break {
    height: 156px;
  }
}

.info {
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: center;
  height: 76px;

  @include min-1024-break {
    gap: 8px;
  }

  @include min-1440-break {
    height: 106px;
  }
}

.left-slot {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-grow: 1;
  justify-content: center;
}

.divider {
  display: none;

  @include min-1024-break {
    display: initial;
  }
}

.right-slot {
  display: none;
  align-items: center;
  justify-content: center;

  @include min-1024-break {
    display: flex;
    min-width: 120px;
  }

  @include min-1440-break {
    min-width: 142px;
  }
}

.image {
  border-radius: 12px;
  margin-top: 10px;
  @include singleFixedSize(56.67px);

  @include min-1440-break {
    @include singleFixedSize(75px);
  }
}

.buttons {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  margin-left: 28px;

  @include min-1024-break {
    display: flex;
  }

  @include min-1440-break {
    gap: 8px;
    margin-left: 34px;
  }
}

.checkbox {
  width: 24px;
  height: 24px;
  margin-top: 25px;

  @include min-1440-break {
    margin-top: 35px;
  }
}

.button {
  width: 34px;
  height: 34px;

  @include min-1440-break {
    width: 42px;
    height: 42px;
  }
}

.title-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.subtitle {
  height: 16px;
  width: 150px;
}

.title {
  height: 16px;
  width: 150px;

  @include min-1024-break {
    height: 28px;
    width: 200px;
  }
}

.price {
  height: 32px;
  width: 100px;
}
