@use '@/app/queries' as *;
@use '@fixed-size' as *;

.add-to-favorite {
  @include singleFixedSize(34px);
  border-radius: var(--cornerradius6);
  @include min-1440-break {
    @include singleFixedSize(42px);
    border-radius: var(--cornerradius8);
  }
}

.liked {
  svg {
    color: #eb3c3c;
  }
}

.icon {
  @include singleFixedSize(16px);

  @include min-1440-break {
    @include singleFixedSize(18px);
  }
}
