@use '@button' as *;
@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.cash {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 24px;
  overflow-y: scroll;
  padding-right: 20px;
  margin-right: -20px;
  height: 872px;

  @include min-1440-break {
    height: 786px;
  }

  .clear {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 42px;

    @include min-1440-break {
      min-height: 46px;
    }

    .info {
      display: flex;
      align-items: center;
      gap: 8px;

      .counter {
        display: flex;
        align-items: center;
        gap: 10px;

        .skeleton {
          width: 24px;
          height: 24px;
        }
      }

      .title {
        color: var(--cst-top-up-cash-out-method-title);
        @include typography-base;
        @include weight-semi-bold;

        @include min-1440-break {
          @include typography-m;
        }
      }
    }

    .buttons {
      display: flex;
      gap: 8px;
      flex-direction: row;
    }
  }

  .additional-info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  .items {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
  }
}

.action-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  @include min-1440-break {
    gap: 8px;
  }

  &-available {
    margin-left: 28px;

    @include min-1440-break {
      margin-left: 34px;
    }
  }
}
