@use '@/app/queries' as *;
@use '@fixed-size' as *;

.remove {
  @include singleFixedSize(34px);
  border-radius: var(--cornerradius6);
  @include min-1440-break {
    border-radius: var(--cornerradius8);
    @include singleFixedSize(42px);
  }

  .icon {
    @include singleFixedSize(16px);

    @include min-1440-break {
      @include singleFixedSize(18px);
    }
  }
}
